$blue: #019eff;
$pink: #f74b8d;
$orange: #fe9725;
$green: #92e204; //#00d395

@keyframes stroke {
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

/* Box style */
.page-box {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1d1e24;
  width: 54.4em;
  height: 65.5em;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow-y: auto;
  overflow-x: hidden;
  transition: background-color 0.3s;
  user-select: none;

  &[data-is-allowed-domain="true"] {
    overflow-y: hidden;
  }
}

.App {
  .loader_container {
    display: flex;
    justify-content: center;
    font-size: 2.5em;
    position: absolute;
    z-index: 5;
    filter: drop-shadow(0.2em 0.2em 0.2em black);
    background: rgba(0, 0, 0, 0.7);
    height: 100%;
    width: 100%;
    align-items: center;
    .item {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .top {
        display: flex;
        justify-content: center;
        align-items: center;

        .ring {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 5em;
          height: 5em;
          border-radius: 50%;
          box-shadow: 0 0 0.2em 0 rgba(0, 0, 0, 0.39);
          transition: all 1s ease-in-out;
          border: 0.6em solid #30323e;
          position: relative;
          transform: rotate(-45deg);

          &.full {
            .image {
              background-image: url("../src/Images/v_check_icon_filled.png");
            }
          }

          .image {
            width: 4em;
            height: 4em;
            background-image: url("../src/Images/v_check_icon_blue.png");
            background-size: cover;
            background-repeat: no-repeat;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
          }

          svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(-90deg);
            .circle {
              stroke-dasharray: 1000;
              stroke-dashoffset: 1000;
              animation: stroke 2s ease-out forwards; // infinite;
              r: 1.1em;
              stroke: #019eff;
              &.loading {
                animation: stroke 2s ease-out forwards infinite;
              }
            }
          }
        }
      }
      .bottom {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 1em;
        text-align: center;

        .text {
          font-size: 1.2em;
          color: #ffffff;
        }
      }
    }
  }
}

/* Header style */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
  position: absolute;
  top: 1em;
  width: 90%;

  .back,
  .close {
    position: absolute;
    left: 1em;
    background-size: cover;
    cursor: pointer;
    width: 2em;
    height: 2em;
    z-index: 5;
  }

  .back {
    background-image: url("../src/Images/back_arrow_icon.png");
  }

  .close {
    background-image: url("../src/Images/close_icon.png");
  }

  .header_title {
    color: white;
    font-family: Helvetica;
    // flex-grow: 1;
    font-size: 2.2em;
    font-weight: 700;
    text-align: center;
    letter-spacing: 0.05em;
    width: 50%;
    // margin-right: 2em;
  }

  .logo {
    background-image: url("../src/Images/top_logo.png");
    background-size: cover;
    background-size: 3em 3em;
    background-repeat: no-repeat;
    background-position: right;
    width: 25%;
    height: 3em;
  }
}

.footer {
  position: absolute;
  bottom: 2em;
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
  height: 5%;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-evenly;

  .footer_text {
    color: #eeeeee;
    text-transform: uppercase;
    padding: 0.4em;
    margin-bottom: 0.4em;
    text-align: center;
  }
  .footer_logo {
    background-image: url("../src/Images/logo_com.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 18em;
    height: 100%;

    &_global {
      background-image: url("../src/Images/logo_com.png");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      width: 18em;
      height: 100%;
    }
  }
}

/* pages */
.page1,
.page2,
.page3,
.page4 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  align-content: center;
}
.error {
  color: white;
  font-size: 1.5em;
}
.loading {
  color: white;
  font-size: 2.5em;
}

.page1 {
  &.global {
    .loader_container {
      display: flex;
      justify-content: center;
      font-size: 2.5em;
      position: absolute;
      z-index: 5;
      filter: drop-shadow(0.2em 0.2em 0.2em black);
      background: rgba(0, 0, 0, 0.7);
      height: 100%;
      width: 100%;
      align-items: center;
      margin-top: 3%;
      .item {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .top {
          display: flex;
          justify-content: center;
          align-items: center;

          .ring {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 5em;
            height: 5em;
            border-radius: 50%;
            box-shadow: 0 0 0.2em 0 rgba(0, 0, 0, 0.39);
            transition: all 1s ease-in-out;
            border: 0.6em solid #30323e;
            position: relative;
            transform: rotate(-45deg);

            &.full {
              .image {
                background-image: url("../src/Images/v_check_icon_filled.png");
              }
            }

            .image {
              width: 4em;
              height: 4em;
              background-image: url("../src/Images/v_check_icon_blue.png");
              background-size: cover;
              background-repeat: no-repeat;
              border-radius: 50%;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%) rotate(45deg);
            }

            svg {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%) rotate(-90deg);
              .circle {
                stroke-dasharray: 1000;
                stroke-dashoffset: 1000;
                animation: stroke 2s ease-out forwards; // infinite;
                r: 1.1em;
                stroke: #019eff;
                &.loading {
                  animation: stroke 2s ease-out forwards infinite;
                }
              }
            }
          }
        }
        .bottom {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 1em;
          text-align: center;

          .text {
            font-size: 1.2em;
            color: #ffffff;
          }
        }
      }
    }
  }
  .page1-title {
    color: white;
    font-size: 2.5em;
    font-weight: 600;
    text-align: center;
    margin-bottom: 1.5em;
    width: 100%;
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .buy-coin-button {
    background-color: #30323e;
    border: 0.1em solid #45485a;
    border-radius: 0.5em;
    color: #ffffff;
    cursor: pointer;
    font-size: 1.5em;
    font-weight: 600;
    margin: 0.3333em 0.6em;
    padding: 1em 1.5em;
    transition: background-color 0.3s;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .buy-coin-button-icon {
      background-image: url("../src/Images/bank_icon.png");
      background-size: cover;
      background-repeat: no-repeat;
      width: 1.5em;
      height: 1.5em;
      margin-right: 0.5em;
    }
    &:hover {
      background-color: darken($blue, 10%);
    }
  }

  .buy-card-button {
    background-color: #30323e;
    border: 0.1em solid #45485a;
    border-radius: 0.5em;
    color: #ffffff;
    cursor: pointer;
    font-size: 1.5em;
    font-weight: 600;
    margin: 0.3333em 0.6em;
    padding: 1.167em 1.5em;
    transition: background-color 0.3s;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .buy-card-button-icons {
      display: flex;
      gap: 0.5em;

      .visa-icon {
        background-image: url("../src/Images/visa_icon.png");
        background-size: contain;
        background-repeat: no-repeat;
        width: 2.4em;
        height: 1.1em;
      }

      .mastercard-icon {
        background-image: url("../src/Images/master_card_icon.png");
        background-size: contain;
        background-repeat: no-repeat;
        width: 1.4em;
        height: 1.1em;
      }
    }

    &:hover {
      background-color: darken($blue, 10%);
    }
  }

  /* ConfirmationPopup styles */
  .confirmation-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #1d1e24;
    border-radius: 0.5em;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    padding: 2em;
    z-index: 1001;
    width: 90%;
    height: 80%;
    max-height: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    .confirmation-popup-content {
      color: #ffffff;

      h1 {
        font-size: 2.4em;
      }

      p {
        font-size: 1.5em;
        line-height: 2em;
        margin: 1em 2em;
      }

      button {
        background-color: $blue;
        border: none;
        border-radius: 0.5em;
        color: #ffffff;
        cursor: pointer;
        font-size: 1.4em;
        font-weight: 600;
        margin: 0 0.5em;
        padding: 0.7em 1.5em;
        transition: background-color 0.3s;

        &:hover {
          background-color: darken($blue, 10%);
        }

        &:first-of-type {
          margin-right: 1em;
        }

        &:last-of-type {
          background-color: #777a82;

          &:hover {
            background-color: darken(#777a82, 10%);
          }
        }
      }
    }
  }

  /* IframePopup styles */
  .iframe-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #1d1e24;
    border-radius: 0.5em;
    // box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    // padding: 2em;
    z-index: 1002; /* Ensure it's above the confirmation popup */
    width: 99%;
    text-align: center;

    .iframe-popup-content {
      position: relative;

      iframe {
        border: none;
        border-radius: 0.5em;
        width: 100%;
        height: 45em;
      }

      button {
        background-color: $blue;
        border: none;
        border-radius: 0.5em;
        color: #ffffff;
        cursor: pointer;
        font-size: 1.2em;
        font-weight: 600;
        margin-top: 1em;
        padding: 0.7em 1.5em;
        transition: background-color 0.3s;

        &:hover {
          background-color: darken($blue, 10%);
        }
      }
    }
  }
}

.page2 {
  width: 90%;
  height: 100%;

  .container {
    display: flex;
    justify-content: center;
    font-size: 2em;
    position: absolute;
    z-index: 11;
    filter: drop-shadow(0.2em 0.2em 0.2em black);
    background: rgba(0, 0, 0, 0.7);
    height: 100%;
    width: 100%;
    align-items: center;

    .item {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .top {
        display: flex;
        justify-content: center;
        align-items: center;

        .ring {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 5em;
          height: 5em;
          border-radius: 50%;
          box-shadow: 0 0 0.2em 0 rgba(0, 0, 0, 0.39);
          transition: all 1s ease-in-out;
          border: 0.6em solid #30323e;
          position: relative;
          transform: rotate(-45deg);

          &.full {
            .image {
              background-image: url("../src/Images/v_check_icon_filled.png");
            }
          }

          .image {
            width: 4em;
            height: 4em;
            background-image: url("../src/Images/v_check_icon_blue.png");
            background-size: cover;
            background-repeat: no-repeat;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
          }

          svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(-90deg);
            .circle {
              stroke-dasharray: 1000;
              stroke-dashoffset: 1000;
              animation: stroke 2s ease-out forwards; // infinite;
              r: 1.1em;
              stroke: #019eff;
              &.loading {
                animation: stroke 2s ease-out forwards infinite;
              }
            }
          }
        }
      }
      .bottom {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 1em;
        text-align: center;

        .text {
          font-size: 1.2em;
          color: #ffffff;
        }
      }
    }
  }

  .page2_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    .currency_input_wrap {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 5.333em;
    }
  }
}

.page3 {
  width: 90%;
  height: 100%;

  .page3_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    .recipient-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      width: 90%;
      margin: 1.5em 0;

      .recipient-label {
        color: #fff;
        font-family: Helvetica;
        font-size: 1.4em;
        line-height: 2.71;
        letter-spacing: 0.05em;
      }
      .recipient-address {
        color: #777a82;
        font-family: Helvetica;
        font-size: 1.4em;
        font-weight: bold;
        letter-spacing: 0.01em;
        line-break: anywhere;
      }
    }
    .button {
      position: relative;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      .button-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        pointer-events: none;
      }

      .loader {
        position: absolute;
        right: 2em;
        transform: translateY(-50%);
        border: 0.2em solid #f3f3f3;
        border-top: 0.2em solid $blue;
        border-radius: 50%;
        width: 1.3em;
        height: 1.3em;
        animation: spin 1.2s linear infinite;
      }

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }

  .page3_bottom_desc1 {
    color: #f74b8d;
    font-weight: 600;
    font-size: 1.3em;
    margin-top: 1.5em;
  }
  .page3_bottom_desc2 {
    color: #f74b8d;
    font-weight: 600;
    font-size: 1.3em;
    margin: 1em 0em;
    padding: 0;
  }
}

.content {
  flex-grow: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

/* coin box style*/
.coin_box {
  display: flex;
  align-items: center;
  width: 12em;
  height: 5em;
  margin: 0.7em 0.7em;
  padding: 0.5em;
  border-radius: 1em;
  border: solid 0.2em #45485a;
  background-color: #30323e;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: lighten(#30323e, 10%);
  }

  .coin_desc_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .coin_desc,
    .coin_desc2 {
      color: white;
      font-weight: 600;
      text-align: center;
      margin: 0em 0.1em;
    }

    .coin_desc {
      font-size: 1.8em;
    }

    .coin_desc2 {
      font-size: 1.3em;
    }
  }

  .coin_icon {
    background-size: cover;
    width: 3.6em;
    height: 3.6em;
    margin: 1em;
    filter: drop-shadow(0em 0em 0em black);
  }
}

/* StatusBar styles*/
.status-bar {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 12em;
  width: 80%;

  .status-item {
    display: flex;
    align-items: center;
    position: relative;
    width: 35%;
    z-index: 3;

    &:nth-child(2) {
      z-index: 2;
    }

    &:nth-child(3) {
      z-index: 1;
    }

    .status-circle {
      height: 1em;
      width: 1em;
      border-radius: 50%;
    }

    .status-line {
      flex-grow: 1;
      height: 0.5em;
      width: 12em;
      border-radius: 1em;
      margin: 0em -0.5em;
    }

    .status-description {
      position: absolute;
      top: -2.2em;
      width: 100%;
      text-align: center;
      font-family: Helvetica;
      font-weight: 700;
      font-size: 1.3em;
    }

    &.blue {
      .status-circle,
      .status-line {
        background-color: $blue;
      }

      .status-description {
        color: $blue;
      }
    }

    &.pink {
      .status-circle,
      .status-line {
        background-color: $pink;
      }

      .status-description {
        color: $pink;
      }
    }

    &.orange {
      .status-circle,
      .status-line {
        background-color: $orange;
      }

      .status-description {
        color: $orange;
      }
    }

    &.gray {
      .status-circle,
      .status-line {
        background-color: #777a82;
        border: solid 0.1em #111216;
      }

      .status-description {
        color: #777a82;
      }
    }
  }
}

/* Input styles */
.input-group {
  display: flex;
  flex-direction: column;
  margin-top: 10%;
  // position: absolute;
  // top: 15em;
  // left: 5em;
  width: 90%;

  .input-container {
    margin: 1em 0;

    .usdp-container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border: solid 0.1em #45485a;
      width: 25%;
      height: 70%;
      padding: 0 1em;
      border-radius: 3em;
      background-color: #1d1e24;
      position: absolute;
      right: 1em;

      .usdp-icon {
        background-size: cover;
        width: 2em;
        height: 2em;
        margin-right: 1em;
      }
      .currency-name {
        color: white;
        font-family: Helvetica;
        font-size: 1.3em;
        font-weight: 700;
      }
    }
  }

  .input {
    background-color: rgba(48, 50, 62, 0.8);
    border: solid 0.1em #18191f;
    border-radius: 0.5em;
    color: #fff;
    height: 100%;
    font-weight: 600;
    width: 100%;
    padding-left: 1em;

    &::placeholder {
      color: #777a82;
      font-family: Helvetica;
      font-size: 1em;
    }

    &:focus-visible {
      outline: solid 0.2em #18191f;
    }

    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type="number"] {
      -moz-appearance: textfield;
      appearance: textfield;
    }
  }

  .input-label {
    color: #777a82;
    font-family: Helvetica;
    font-size: 1.3em;
    line-height: 2.71;
  }
}

/* Currency Button styles */
.currency-selector {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border: solid 0.1em #45485a;
  width: 25%;
  height: 70%;
  padding: 0 1em;
  border-radius: 3em;
  background-color: #1d1e24;
  position: absolute;
  right: 1em;
  user-select: none;

  .currency-info {
    display: flex;
    align-items: center;

    .currency-icon,
    .currency-name {
      margin-right: 1em;
    }

    .currency-icon {
      background-size: cover;
      width: 2em;
      height: 2em;
    }

    .currency-name {
      color: white;
      font-family: Helvetica;
      font-size: 1.3em;
      font-weight: 700;
    }
  }

  .arrow-icon {
    background-image: url("../src/Images/arrow-down.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 1em;
    height: 1em;
    transition: transform 0.3s ease-in-out;

    &.up {
      transform: rotate(180deg);
    }
  }
}

.currency-popup {
  position: absolute;
  top: 5em;
  left: 0em;
  width: 99%;
  height: max-content;
  background-color: #1d1e24;
  border: solid 0.1em #45485a;
  border-radius: 0.5em;
  z-index: 1;
  opacity: 0.97;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  //user-select: none;

  .coin_box {
    width: 23%;
    //margin: 0.6em auto;
    margin: 0.6em 0.5em;
    padding: 0em;
    box-sizing: border-box;
    font-size: 0.8em;

    &:hover {
      background-color: lighten(#30323e, 10%);
    }
  }
}

.currency-popup {
  visibility: hidden;
  opacity: 0;
  transform: translateY(-2em);

  &.isPopupVisible {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
    animation: fadeInUp 0.8s ease forwards;
  }

  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(-2em);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

/* Exchange styles */
.exchange-rate {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  // position: absolute;
  width: 90%;
  margin-top: 0.5em;
  // top: 35em;
  // left: 5em;

  .exchange-txt-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10%;
  }

  .funds-txt-row {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 3%;
  }

  .exchange-rate-label,
  .fee {
    color: #777a82;
    font-family: Helvetica;
    font-size: 1.3em;
    font-weight: 700;
  }
}

.funds-transferred {
  // position: absolute;
  // top: 5em;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.1em;

  .funds-text {
    font-family: Helvetica;
    font-size: 1.25em;
    letter-spacing: 0.01em;
    text-align: center;
    color: #777a82;
    margin-left: 0.5em;
  }

  .funds-icon {
    background-image: url("../src/Images/Time-icon.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 1.8em;
    height: 2.1em;
  }
}

.button {
  border-radius: 0.6em;
  border: none;
  cursor: pointer;
  height: 3.6em;

  span {
    font-family: Helvetica;
    font-size: 1.1em;
    font-weight: 600;
    color: white;
    text-align: center;
    letter-spacing: 0.05em;
  }

  &.blue {
    background-color: $blue;
    width: 100%;

    &.disabled {
      background-color: rgba($blue, 0.3);
    }
  }

  &.pink {
    background-color: $pink;
    width: 90%;
    height: 3.2em;
    position: relative;

    &.disabled {
      background-color: rgba($pink, 0.3);
    }
  }

  &.green {
    cursor: default;
    background-color: $green;
    width: 80%;
    // top: 35em;

    &.disabled {
      background-color: rgba($green, 0.3);
    }
  }

  &.disabled {
    cursor: default;

    /* span {
      font-weight: 500;
    } */
  }
}

/* Page3 styles */

.send-address-container {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-top: 10%;
  position: relative;

  .send-address-label {
    color: #fff;
    font-family: Helvetica;
    font-size: 1.5em;
    line-height: 2.71;
  }

  .send-address-input {
    background-color: rgba(48, 50, 62, 0.8);
    border: solid 0.1em #18191f;
    border-radius: 0.5em;
    color: #adadad;
    height: 3.8em;
    font-weight: 600;
    padding-left: 1em;
    cursor: default;

    &::placeholder {
      color: #777a82;
      font-family: Helvetica;
      font-size: 1em;
    }
    &:focus-visible {
      outline: solid 0.2em #18191f;
    }
    &:hover + .tooltip {
      visibility: visible;
      opacity: 1;
    }
  }
  .tooltip {
    visibility: hidden;
    opacity: 0;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 58%;
    left: 34%;
    transform: translateX(-50%);
    width: fit-content;
    transition:
      visibility 0.2s,
      opacity 0.2s;
    font-size: 1.2em;
  }
  .icon {
    background-image: url("../src/Images/copy_icon_blue.png");
    cursor: pointer;
    position: relative;
    background-size: contain;
    background-repeat: no-repeat;
    width: 2.8em;
    height: 2.5em;
    bottom: 30%;
    left: 88%;

    &::after {
      content: attr(data-copied);
      position: absolute;
      display: none;
      background-color: #000;
      color: #fff;
      padding: 0.5em;
      border-radius: 0.5em;
      top: -3em;
      left: 50%;
      transform: translateX(-50%);
      white-space: nowrap;
    }

    &:hover::after {
      display: block;
    }
    &[data-copied="false"]:hover::after {
      /* content: "Copy address"; */
      content: attr(data-copy-text);
    }
    &[data-copied="true"]:hover::after {
      /* content: "Copy address"; */
      content: attr(data-copied-text);
    }
  }
}

.swap-timer {
  font-family: Helvetica;
  display: flex;
  align-items: center;
  gap: 0.4em;
  font-size: 1.3em;
  color: #f74b8d;
  flex-direction: row;
  font-weight: 600;
  justify-content: space-between;
  .swap-timer-time {
    color: #f74b8d;
    font-size: 1.3em;
    font-weight: 600;
    margin-right: 0.1em;
  }
}

.qr-container {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  width: 90%;
  margin-top: 5%;
  svg {
    cursor: zoom-in;
    width: 12.5em !important;
    height: 12.5em !important;
    filter: drop-shadow(1px 1px 3px #000);
    border-radius: 1em;
  }
  canvas {
    width: 11.875em !important;
    height: 11.75em !important;
  }
  .info-container {
    display: flex;
    flex-direction: column;
    align-content: center;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: stretch;
    width: 70%;
    border-top: solid 0.16em #777a82;
    border-bottom: solid 0.16em #777a82;

    .info-row-network,
    .info-row-address {
      display: flex;

      .info-text {
        color: #777a82;
        font-family: Helvetica;
        font-size: 1.3em;
        font-weight: 700;
        display: flex;
        flex-direction: column;
        width: 70%;
        align-items: baseline;
        justify-content: center;
        margin-left: 1em;

        .info-label {
          color: #adadad;
          font-family: Helvetica;
          font-size: 1.1em;
          font-weight: 500;
          width: 100%;
          margin: 0em 0 0.4em 0;
        }

        .info-value {
          color: #fff;
          font-family: Helvetica;
          font-size: 1.2em;
          font-weight: 500;
          line-height: 1.5em;
          overflow-wrap: break-word;
          overflow: hidden;
          margin: 0.4em 0 0.8em 0;
        }
      }

      .icon {
        background-size: contain;
        background-repeat: no-repeat;
        width: 2.6em;
        height: 2em;
        margin: auto;
        margin-right: 1em;

        &.network {
          background-image: url("../src/Images/switch_icon_blue.png");
        }
      }
    }

    .info-row-network {
      // border-bottom: solid 0.16em #d2d6db;
      // margin-bottom: 1em;

      .icon {
        background-image: url("../src/Images/switch_icon.png");
      }
    }

    .info-row-address {
      .icon {
        background-image: url("../src/Images/copy_icon.png");
        cursor: pointer;
        position: relative;

        &::after {
          content: attr(data-copied);
          position: absolute;
          display: none;
          background-color: #000;
          color: #fff;
          padding: 0.5em;
          border-radius: 0.5em;
          top: -3em;
          left: 50%;
          transform: translateX(-50%);
          white-space: nowrap;
        }

        &:hover::after {
          content: "Copied";
          display: block;
        }
        &[data-copied="false"]:hover::after {
          content: "Copy address";
        }
      }
    }
  }
}

/* Page4 styles */

.page4 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .container {
    display: flex;
    justify-content: space-between;
    height: 50%;
    position: relative;
    top: 7%;

    .item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 5em 2em 2em 2em;

      .top {
        display: flex;
        justify-content: center;
        align-items: center;

        .ring {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 7em;
          height: 7em;
          border-radius: 50%;
          box-shadow: 0 0 0.2em 0 rgba(0, 0, 0, 0.39);
          transition: all 1s ease-in-out;
          border: 0.6em solid #30323e;
          position: relative;
          transform: rotate(-45deg);

          &.full {
            .image {
              background-image: url("../src/Images/v_check_icon_filled.png");
            }
          }

          .image {
            width: 4em;
            height: 4em;
            background-image: url("../src/Images/v_check_icon.png");
            background-size: cover;
            background-repeat: no-repeat;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
          }

          svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(-90deg);
            .circle {
              stroke-dasharray: 1000;
              stroke-dashoffset: 1000;
              animation: stroke 2s ease-out forwards; // infinite;
              r: 4em;
              &.loading {
                animation: stroke 2s ease-out forwards infinite;
                r: 1.4em;
              }
            }
          }
        }
      }
      .bottom {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 1em;
        text-align: center;

        .text {
          font-size: 1.2em;
          color: #ffffff;
        }
      }
    }
  }
  .amount-sent-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0.6em;
    border: none;
    // position: absolute;
    height: 5.5em;
    background-color: rgba($orange, 0.19);
    width: 80%;
    margin-bottom: 2em;
    // top: 38.5em;

    .usdp-text {
      color: white;
      font-family: Helvetica;
      font-size: 1.2em;
      font-weight: 500;
      line-height: normal;
      margin-left: 2em;
    }
    .usdp-info {
      display: flex;
      align-items: center;

      .usdp-icon {
        background-image: url("../src/Images/USDP.png");
        background-size: cover;
        width: 2em;
        height: 2em;
        margin-right: 0.7em;
      }
      .usdp-amount {
        color: white;
        font-family: Helvetica;
        font-size: 1.7em;
        font-weight: 600;
        line-height: normal;
        margin-right: 1em;
      }
    }
  }
}

.page4Global {
  width: 100%;
  height: 100%;

  .container {
    display: flex;
    justify-content: center;
    font-size: 1.2em;
    position: absolute;
    z-index: 11;
    filter: drop-shadow(0.2em 0.2em 0.2em black);
    background: rgba(0, 0, 0, 0.7);
    height: 100%;
    width: 100%;
    align-items: center;
    top: 0em;
    flex-direction: column;

    .item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 1em;
      font-size: 1.4em;

      .top {
        display: flex;
        justify-content: center;
        align-items: center;

        .ring {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 5em;
          height: 5em;
          border-radius: 50%;
          box-shadow: 0 0 0.2em 0 rgba(0, 0, 0, 0.39);
          transition: all 1s ease-in-out;
          border: 0.6em solid #30323e;
          position: relative;
          transform: rotate(-45deg);

          &.full {
            .image {
              background-image: url("../src/Images/v_check_icon_blue.png");
            }
          }

          .image {
            width: 4em;
            height: 4em;
            background-image: url("../src/Images/v_check_icon.png");
            background-size: cover;
            background-repeat: no-repeat;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
          }

          svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(-90deg);
            .circle {
              stroke-dasharray: 1000;
              stroke-dashoffset: 1000;
              animation: stroke 2s ease-out forwards;
              r: 40; // Adjust the radius if needed
              stroke: #019eff;

              &.loading {
                animation: stroke 2s ease-out forwards infinite;
              }
            }
          }
        }
      }
      .bottom {
        display: inline-block;
        justify-content: center;
        align-items: center;
        margin-top: 1em;
        text-align: center;

        .text {
          display: block;
          font-size: 1.2em;
          color: #ffffff;
          margin: 0 1em;
        }
      }
    }
  }
}

.page-box {
  &.light {
    background-color: #ffffff;

    .page1 {
      .page1-title {
        color: #012734;
      }
      .buy-coin-button,
      .buy-card-button {
        background-color: #eaf2ff;
        border: 0.1em solid #d2d6db;
        color: #012734;

        &:hover {
          background-color: lighten($blue, 10%);
        }

        .buy-coin-button-icon {
          filter: invert(1);
        }
        .buy-card-button-icons {
          .visa-icon {
            filter: brightness(0.5);
          }
          .mastercard-icon {
            filter: drop-shadow(0.1em 0.2em 0.2em black);
          }
        }
      }

      .confirmation-popup {
        background-color: #ffffff;

        .confirmation-popup-content {
          color: #012734;

          h1,
          p {
            color: #012734;
          }

          button {
            background-color: $blue;
            color: #012734;

            &:hover {
              background-color: lighten($blue, 10%);
            }

            &:last-of-type {
              background-color: #efefef;
              border: solid 0.1em #d2d6db;

              &:hover {
                background-color: lighten(#efefef, 10%);
              }
            }
          }
        }
      }

      .iframe-popup {
        background-color: #ffffff;

        .iframe-popup-content {
          iframe {
            border: 0.1em solid #d2d6db;
          }

          button {
            background-color: $blue;
            color: #012734;

            &:hover {
              background-color: lighten($blue, 10%);
            }
          }
        }
      }
    }
    .header {
      .header_title {
        color: #012734;
      }
      .back {
        background-image: url("../src/Images/back_arrow_icon_dark.png");
      }
      .close {
        filter: invert(1);
      }
    }

    /* .footer_logo {
      background-image: url('../src/Images/footer_logo_dark.png');
    } */

    .footer {
      .footer_text {
        color: #012734;
      }
      .footer_logo {
        background-image: url("../src/Images/logo_dark_com.png");
        &_global {
          background-image: url("../src/Images/logo_dark_com.png");
        }
      }
    }

    .coin_box {
      background-color: #eaf2ff;
      border: solid 0.2em #d2d6db;
      &:hover {
        background-color: darken(#eaf2ff, 10%);
      }
      .coin_desc_container {
        .coin_desc,
        .coin_desc2 {
          color: #012734;
          font-weight: 700;
        }
      }
      .coin_icon {
        filter: drop-shadow(0.1em 0.2em 0.2em black);
        &[data-img="UNI"] {
          content: url("../src/Images/UNI_DARK.png");
        }
      }
    }

    .status-bar {
      .status-item.gray {
        .status-circle,
        .status-line {
          background-color: #efefef;
          border: solid 0.1em #d2d6db;
        }
        .status-description {
          color: #012734;
        }
      }
    }

    .input-group {
      .input {
        background-color: #f5f5f5;
        border: solid 0.1em #d2d6db;
        color: #012734;
      }
      .input-label {
        color: #012734;
        font-weight: 600;
      }
      .input-container {
        .usdp-container {
          background-color: #ffffff;
          border: solid 0.1em #d2d6db;
          .currency-name {
            color: #012734;
          }
        }
      }
    }

    .currency-selector {
      background-color: #ffffff;
      border: solid 0.1em #d2d6db;
      .currency-info {
        .currency-icon {
          filter: drop-shadow(0.1em 0.2em 0.2em black);
          &[data-img="UNI"] {
            content: url("../src/Images/UNI_DARK.png");
          }
        }
        .currency-name {
          color: #012734;
        }
      }
      .arrow-icon {
        filter: invert(1);
      }
    }

    .currency-popup {
      background-color: #ffffff;
      border: solid 0.1em #d2d6db;
    }

    .send-address-container {
      .send-address-label {
        color: #012734;
      }
      .send-address-input {
        background-color: rgba(239, 239, 239, 0.8);
        border: solid 0.1em #d2d6db;
        color: #012734;
      }
    }
    .qr-container .info-container {
      .swap-timer-time {
        color: #012734;
      }

      .info-row-network,
      .info-row-address {
        .info-text {
          .info-label {
            color: #012734;
          }
          .info-value {
            color: #012734;
          }
        }
      }
    }

    .button {
      span {
        filter: drop-shadow(0.5px 0.5px 2px black);
      }
    }
    .page3 .page3_content {
      .recipient-container {
        .recipient-label {
          color: #012734;
        }
        .recipient-address {
          color: #012734;
          opacity: 0.6;
        }
      }
      .button {
        .loader,
        .button-text {
          filter: drop-shadow(0.5px 0.5px 2px black);
        }
      }
    }
    .page4 {
      .amount-sent-container {
        .usdp-text {
          color: #012734;
          font-weight: 600;
        }
        .usdp-info {
          .usdp-amount {
            color: #012734;
          }
        }
      }
      .item {
        .top {
          .ring {
            border: 0.6em solid #efefef;
            &.full {
              .image {
                background-image: url("../src/Images/v_check_icon_filled.png");
              }
            }
            .image {
              background-image: url("../src/Images/v_check_icon_light.png");
            }
          }
        }
        .bottom {
          .text {
            color: #012734;
            font-weight: 600;
          }
        }
      }
    }
  }
}

body {
  --bg-color: #1d1e24;
  --text-color: #333;
  background-color: var(--bg-color);
  color: var(--text-color);

  &.dark-mode {
    --bg-color: #333;
    --text-color: #fff;
  }
}

.theme-toggle {
  position: relative;
  width: 25%;
  display: flex;
  justify-content: center;
  // padding-left: 2em;
  // margin-left: 2em;

  .toggle-checkbox {
    display: none;

    &:checked + .toggle-label {
      background-color: #d2d6db;
      border: solid 0.1em #d2d6db;

      .toggle-button {
        background-color: #777a82;
      }

      .theme-icon {
        transform: translateX(2.5em);
      }
    }
  }

  .toggle-label {
    display: flex;
    align-items: center;
    width: 5em;
    height: 2.5em;
    background-color: #30323e;
    border: solid 0.1em #111216;
    border-radius: 2.5em;
    cursor: pointer;
    position: relative;
  }

  .toggle-button,
  .theme-icon {
    display: block;
    width: 1.8em;
    height: 1.8em;
    position: absolute;
    top: 0.35em;
    transition: transform 0.3s ease;
  }

  .toggle-button {
    background-color: #777a82;
    border-radius: 50%;
    right: 0.3em;
  }

  .theme-icon {
    left: 0.3em;
  }

  .toggle-checkbox:checked + .toggle-label .toggle-button {
    transform: translateX(-2.5em);
  }
}

//                              Media Queries
@media screen and (max-width: 480px) {
  /* Box style */
  .page-box {
    top: 0;
    left: 0;
    transform: none;
    width: 100%;
    height: 100%;
    border-radius: 0;
    flex-direction: column;

    .content {
      align-items: flex-start;
    }

    .page1 {
      width: 90%;
      height: 85%;
      flex-wrap: wrap;
      justify-content: flex-start;
    }

    .page2,
    .page3,
    .page4 {
      width: 90%;
      height: 90%;
    }

    .page4 {
      .container {
        height: 40%;
        .item {
          margin: 0em 0.4em;
        }
      }
      .button.green {
        width: 90%;
      }
    }

    /* Header style */
    .header {
      position: relative;
      top: 0;
      width: 100%;
      padding: 2em;
      justify-content: space-between;

      .header_title {
        font-size: 2.3em;
        width: 50%;
        // margin-right: 3em;
      }

      .logo {
        width: 25%;
        height: 3.5em;
        // margin-right: 2em;
        background-image: none;
      }
      .theme-toggle {
        margin-left: 0;
        width: 25%;
        padding: 0;
        display: flex;
        justify-content: flex-end;
      }
      .close,
      .back {
        position: absolute;
        left: 3.5em;
        width: 3em;
        height: 3em;
        z-index: 5;
      }
      .theme-toggle .toggle-label {
        font-size: 1em;
        // margin: 0 2em;
      }
    }

    .qr-overlay {
      border-radius: 2em;
    }
  }
  .page1 {
    .coin_box {
      width: 27%;
      height: 6em;
      margin: 0.5em 0.5em;

      flex: 1 1 calc(33.33% - 1em); // Each item takes up roughly one-third of the width
      margin: 0.5em; // Adjust margin as needed
      box-sizing: border-box;

      &[data-index="0"]:nth-last-of-type(1),
      &[data-index="1"]:nth-last-of-type(1),
      &[data-index="0"]:nth-last-of-type(2),
      &[data-index="1"]:nth-last-of-type(2) {
        justify-content: center;
      }
      .coin_icon {
        margin: 0.4em;
      }
    }
  }

  .page2 {
    .currency-selector {
      .currency-info {
        .currency-name {
          font-size: 1.2em;
          margin-right: 0.7em;
          width: min-content;
        }
      }
    }

    .input-group {
      margin: 0;
      width: 90%;
      .input {
        width: 100%;
        height: 3em;
        font-size: 1.6em;
      }
      .input-container {
        .currency-popup {
          &.isPopupVisible {
            // flex-direction: column;
            width: 99%;

            .coin_box {
              width: 22%;
              margin: 0.6em 0.5em;
              padding: 0em;
              box-sizing: border-box;
              font-size: 0.8em;

              .coin_icon {
                width: 3em;
                height: 3em;
                margin: 0.5em;
              }
              .coin_desc_container {
                .coin_desc {
                  font-size: 1.6em;
                  margin: 0em 0.01em;
                }
              }
            }
          }
        }
      }
    }
  }
  .page3 {
    .send-address-container {
      margin: 0;
    }
    .qr-container {
      width: 90%;
      gap: 3.5%;
      canvas {
        width: 16.5em !important;
        height: 16.5em !important;
      }
      .info-container {
        width: 54%;
        font-size: 1em;

        .swap-timer {
          font-size: 1.2em;
          gap: 0.3em;
        }

        .info-row-address {
          .info-text {
            .info-value {
              font-size: 0.8em;
            }
          }
        }
      }
    }
    .page3_bottom_desc1 {
      margin-top: 0.5em;
    }
  }
  .page4 {
    padding-top: 5%;

    .amount-sent-container {
      margin-top: 8%;
    }

    .container {
      .item {
        .top .ring {
          width: 8em;
          height: 8em;

          .image {
            width: 5em;
            height: 5em;
          }
        }
        padding-top: 0;
        // margin: 1em;
      }
    }
    .amount-sent-container {
      width: 90%;
      // top: 36.5em;
    }
  }
} //End of Media 480px

@media screen and (max-width: 400px) {
  .page-box {
    .input-group .input {
      font-size: 16px; //
      height: 2.4em;
      &::placeholder {
        font-size: 0.8em;
      }
    }

    &[data-is-allowed-domain="true"] {
      .button {
        height: 2.6666rem;
        margin-top: 1.5em;
      }
      .page1 {
        height: 70%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;

        .coin_box {
          flex: 1 1 calc(33.33% - 1em); // Each item takes up roughly one-third of the width
          margin: 0.5em; // Adjust margin as needed
          box-sizing: border-box;

          &[data-index="0"]:nth-last-of-type(1),
          &[data-index="1"]:nth-last-of-type(1),
          &[data-index="0"]:nth-last-of-type(2),
          &[data-index="1"]:nth-last-of-type(2) {
            justify-content: center;
          }
        }
      }
      .page2 {
        .button.blue {
          width: 100%;
        }
      }
      .content {
        padding-top: 15%;
        .page2 {
          .container {
            top: 0;
          }
          .page2_content {
            padding-bottom: 15%;
          }
        }
        .page3 {
          margin-top: -8%;
          .qr-container .info-container .swap-timer {
            color: $blue;
          }
          .page3_bottom_desc2 {
            color: $blue;
          }
        }
      }
      .input-group .input {
        height: 2.4165em;
      }
      .page3 {
        .button.blue {
          width: 90%;
        }
        .qr-container {
          width: 89%;
          gap: 3.5%;
          .info-container {
            width: 60%;
          }
        }
        .send-address-container .send-address-input {
          height: 3.606em;
          width: 94.84%;
        }
      }
    } // End of Allowed Domain

    .header {
      font-size: 0.7em;

      .theme-toggle .toggle-label {
        font-size: 1.2em;
      }
    }
    .content {
      font-size: 0.8em;
    }
    .exchange-rate {
      // top: 38em;
    }
    .currency-selector {
      height: 3.8em;
    }
    .page2 .input-group .input-container {
      .currency-popup.isPopupVisible {
        top: 6em;

        .coin_box .coin_desc_container .coin_desc {
          font-size: 1.5em;
        }
      }
      .usdp-container {
        height: 3.8em;
      }
    }

    .page3 {
      .qr-container {
        width: 87%;
        gap: 3.5%;

        canvas {
          // width: 16.2em !important;
          // height: 16.2em !important;
          width: 11.875em !important;
          height: 11.75em !important;
        }
        .info-container {
          width: 57%;
          .swap-timer {
            font-size: 1.2em;
            gap: 0.3em;
          }
        }
      }
      .send-address-container {
        // top: 16em;
        .send-address-input {
          width: 93.5%;
          font-size: 1.34em;
          // top: 17em;
        }
      }
    }

    .page4 {
      .container {
        .item {
          margin: 1.6em;
          .top .ring {
            width: 7em;
            height: 7em;
            svg .circle {
              // r: 3.5em;
            }
          }
          .bottom {
            margin-top: 2.5em;
          }
        }
      }
      .button.green {
        width: 90%;
        // top: 30em;
      }
    }

    .footer {
      .footer_text {
        font-size: 0.9em;
      }
    }
  }
} //End of Media 400px

// max-width: 375px and max-height: 667px
@media screen and (max-width: 375px) and (max-height: 667px) {
  .page-box {
    .page3 {
      .page3_content {
        margin-top: 10%;
      }
    }
  }
} // End of Media 375px

@media screen and (max-width: 325px) {
  /* .footer {
    position: relative;
    bottom: -1em;
    left: 0;
    width: 90%;
    height: 7em;
  }

  .footer_logo {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      position: relative;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 50%;
      height: 6em;
  } */

  .footer {
    .footer_text {
      font-size: 0.7em;
    }
  }

  .button.pink {
    padding: 1.4em;
  }
  .page-box {
    overflow-y: auto;
    overflow-x: hidden;

    .header {
      position: relative;
      top: 2em;
      width: 100%;
      // padding: 5em 2em;
      justify-content: space-between;

      .back,
      .close {
        width: 2.3em;
        height: 2.3em;
      }

      .theme-toggle .toggle-label {
        font-size: 1.3em;
      }
    }

    .info-container {
      width: 53%;
      font-size: 1em;
    }
    .page4 {
      .container {
        .item {
          margin: 1.1em;
        }
      }
    }
  }
} //End of Media 325px

@media (min-width: 481px) and (max-width: 932px) and (orientation: landscape) {
  .page-box {
    /* width: 74em;
    height: 41em; */
    width: 100%;
    height: 100%;
    font-size: 0.8em;
    justify-content: flex-start;
    overflow-y: auto;
    // display: none;

    .header {
      .header_title {
        margin-right: 0;
      }
    }
    .content {
      padding-top: 12%;

      .page1 {
        width: 70%;
      }
      .page2 {
        .exchange-rate .exchange-txt-row {
          margin-bottom: 4%;
        }

        .input-group {
          width: 85%;
          left: 10em;

          input {
            width: 31em;
            height: 3em;
          }
          .input-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            .currency-selector {
              // top: 1.4em;
            }
            .currency-popup {
              top: 5.6em;
              left: -0.3em;
              width: 100%;
              height: 15em;
              flex-direction: column;
              .coin_box {
                width: 18.7%;
              }
            }
            .usdp-container {
              // top: 7.8em;
            }
          }
        }
        .exchange-rate {
          width: 85%;
          // top: 28em;
          // left: 10em;
          .funds-transferred {
            // top: 4em;
            .button {
              &.blue {
                background-color: #019eff;
                width: 100%;
                top: 3em;
                &.disabled {
                  background-color: rgba(1, 158, 255, 0.3);
                }
              }
            }
          }
        }
      }
      .page3 {
        .button.pink {
          // margin-top: 2%;
          width: 85%;
          height: 3.5em;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 1.6em 0em;
        }

        .qr-container {
          justify-content: space-evenly;
          position: relative;
          // top: 7.5em;
          width: 95%;
          margin-top: 2%;

          canvas {
            width: 15em !important;
            height: 15em !important;
          }
        }
        .send-address-container {
          // top: 15em;
          // left: 10em;
          width: 85%;
          margin-top: 5%;
          .send-address-input {
            width: 100%;
          }
        }
      }
      .page4 {
        .item .bottom {
          margin-top: 2em;
        }

        .container {
          top: 14em;
        }
        .amount-sent-container {
          top: 28em;
        }
      }
      .button.green {
        top: 27em;
      }
    }

    /* .footer_logo {
      bottom: -12em;
      width: 20%;
      height: 7em;
    } */

    .footer {
      bottom: -31em;
      height: 15%;
    }
  }
} //End of Media landscape

.qr-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8); // Dark background with some transparency
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; // Ensure it appears above other elements
  backdrop-filter: blur(0.3em);
}
.fade-in {
  animation: fadeIn 0.3s forwards;
}

@keyframes fadeIn {
  from {
    transform: scale(0.85);
  }
  to {
    transform: scale(1);
  }
}

.qr-overlay-content {
  background-color: #d4d4d4; // Softer gray background color for the QR code container
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.95); // Add shadow for prominence
  transform: scale(2); // Slightly enlarge the QR code for easy scanning
  svg {
    cursor: zoom-out;
  }
}
